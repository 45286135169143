<template>
  <email-template-edit-create :organization-id="$auth.me.organization_id" />
</template>

<script>
import Vue from 'vue';
import EmailTemplateEditCreate from '~/pages/shared/email-templates/partials/EditCreate.vue';

export default Vue.extend({

  components: {
    EmailTemplateEditCreate,
  },

  middleware: 'employee',
});
</script>
